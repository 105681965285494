import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import engImg from '../../assets/eng.png';
import hunImg from '../../assets/hun.png';
import gerImg from '../../assets/ger.png';
import {
  changeLanguageToEngAction,
  changeLanguageToHunAction,
  changeLanguageToGerAction,
} from '../../actions';
import { gsap } from 'gsap';

export const LanguageSwitcher = ({ height, className }) => {
  const { language } = useSelector((state) => state.language);
  const languageHolderRef = useRef();
  const dispatch = useDispatch();
  const [hoveredFlag, setHoveredFlag] = useState(null);

  useEffect(() => {
    gsap.fromTo(
      languageHolderRef.current,
      { y: '-40vh' },
      { delay: 0.3, duration: 0.4, y: '0vh' }
    );
  }, []);

  const handleEngClick = () => {
    dispatch(changeLanguageToEngAction());
  };

  const handleHunClick = () => {
    dispatch(changeLanguageToHunAction());
  };

  const handleGerClick = () => {
    dispatch(changeLanguageToGerAction());
  };

  const handleMouseEnter = (flag) => {
    setHoveredFlag(flag);
  };

  const handleMouseLeave = () => {
    setHoveredFlag(null);
  };

  const getFlagStyle = (flag) => {
    if (hoveredFlag === flag) {
      return {
        filter: 'grayscale(0.2) brightness(94%)',
        transition: 'filter 0.5s ease-in-out',
      };
    }
    return {
      filter: 'grayscale(1) brightness(99%)',
      transition: 'filter 0.5s ease-in-out',
    };
  };

  return (
    <div ref={languageHolderRef}>
      {language === 'hun' ? (
        <div>
          <img
            className={className}
            src={hunImg}
            onClick={handleHunClick}
            height={height}
            alt="switch language to hungarian"
          />
          <img
            className={className}
            src={engImg}
            onClick={handleEngClick}
            height={height}
            style={getFlagStyle('eng')}
            onMouseEnter={() => handleMouseEnter('eng')}
            onMouseLeave={handleMouseLeave}
            alt="váltás angol nyelvre"
          />
          <img
            className={className}
            src={gerImg}
            onClick={handleGerClick}
            height={height}
            style={getFlagStyle('ger')}
            onMouseEnter={() => handleMouseEnter('ger')}
            onMouseLeave={handleMouseLeave}
            alt="wechseln zur deutschen Sprache"
          />
        </div>
      ) : language === 'eng' ? (
        <div>
          <img
            className={className}
            src={hunImg}
            onClick={handleHunClick}
            height={height}
            style={getFlagStyle('hun')}
            onMouseEnter={() => handleMouseEnter('hun')}
            onMouseLeave={handleMouseLeave}
            alt="switch language to hungarian"
          />
          <img
            className={className}
            src={engImg}
            onClick={handleEngClick}
            height={height}
            alt="váltás angol nyelvre"
          />
          <img
            className={className}
            src={gerImg}
            onClick={handleGerClick}
            height={height}
            style={getFlagStyle('ger')}
            onMouseEnter={() => handleMouseEnter('ger')}
            onMouseLeave={handleMouseLeave}
            alt="wechseln zur deutschen Sprache"
          />
        </div>
      ) : (
        <div>
          <img
            className={className}
            src={hunImg}
            onClick={handleHunClick}
            height={height}
            style={getFlagStyle('hun')}
            onMouseEnter={() => handleMouseEnter('hun')}
            onMouseLeave={handleMouseLeave}
            alt="switch language to hungarian"
          />
          <img
            className={className}
            src={engImg}
            onClick={handleEngClick}
            height={height}
            style={getFlagStyle('eng')}
            onMouseEnter={() => handleMouseEnter('eng')}
            onMouseLeave={handleMouseLeave}
            alt="váltás angol nyelvre"
          />
          <img
            className={className}
            src={gerImg}
            onClick={handleGerClick}
            height={height}
            alt="wechseln zur deutschen Sprache"
          />
        </div>
      )}
    </div>
  );
};