import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HeaderButton } from '../';
import { Translator } from '../index';
import { LogoImg } from '../index';
import { gsap } from "gsap";

export const HeaderLoggedOutDesktop = () => {
  const navigate = useNavigate();
  const sideHeaderRef = useRef();

  const handlePrices = () => {
    navigate('/prices');
  };

  const handleGallery = () => {
    navigate('/gallery');
  };

  const handleContactus = () => {
    navigate('/contactus');
  };

  useEffect(() => {
    gsap.fromTo(sideHeaderRef.current, { x: '-80vw' }, { delay: 1.35, duration: 1.5, x: '-30vw' });
  }, []);

  return (
    <div className="navContainerDesktop" ref={sideHeaderRef}>
      <LogoImg className="logoImg" />
      <HeaderButton
        data-testid="0003"
        innerText={
          <span>
            <span>
              <span>{Translator('Galéria', 'Gallery', 'Galerie')}</span>
            </span>
          </span>
        }
        onClickEvent={handleGallery}
      />
      <HeaderButton
        innerText={
          <span>
            <span>
              <span>{Translator('Áraink', 'Prices', 'Preise')}</span>
            </span>
          </span>
        }
        onClickEvent={handlePrices}
      />
      <HeaderButton
        innerText={
          <span>
            <span>
              <span>{Translator('Kapcsolat', 'Contact Us', 'Kontaktieren Sie uns')}</span>
            </span>
          </span>
        }
        onClickEvent={handleContactus}
      />
    </div>
  );
};