import {
  CHANGE_LANGUAGE_ENG,
  CHANGE_LANGUAGE_HUN,
  CHANGE_LANGUAGE_GER, // Import new action type
  UNLOAD_STORE,
} from '../constants/actionTypes';

const initialState = {
  language: 'hun',
};

export const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE_ENG:
      return {
        ...state,
        language: 'eng',
      };
    case CHANGE_LANGUAGE_HUN:
      return {
        ...state,
        language: 'hun',
      };
    case CHANGE_LANGUAGE_GER: // Handle new action type
      return {
        ...state,
        language: 'ger',
      };
    case UNLOAD_STORE:
      return initialState;
    default:
      return state;
  }
};