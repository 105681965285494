import React from 'react';
import { ContactDetails } from './ContactDetails';
import '../../styles/ContactUs.css';
export const ContactUs = () => {

  return (
    <div className="messageBox">
      <ContactDetails component={ContactDetails} />
    </div>
  );
};