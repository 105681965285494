const API_URL = process.env.REACT_APP_EXCHANGE_RATE_API_URL;

const fetchExchangeRate = async () => {
  try {
    const response = await fetch(API_URL);
    const data = await response.json();
    return data.conversion_rates.HUF;
  } catch (error) {
    console.error('Error fetching exchange rate:', error);
    return null;
  }
};

export const PriceUpdater = async (priceInEUR) => {
  const exchangeRate = await fetchExchangeRate();
  if (exchangeRate === null) {
    throw new Error('Failed to fetch exchange rate');
  }
  const priceInHUF = priceInEUR * exchangeRate;
  const roundedPriceInHUF = Math.round(priceInHUF / 1000) * 1000;
  return roundedPriceInHUF;
};