import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { NotImplementedYet, Header, Login, LandingPage, ContactUs, Prices, Footer, GetLocation, CookieConsentBar, Loader } from './components';
import { useDispatch } from 'react-redux';
import { loadUserDataAction } from './actions';
import { auth } from './firebase.js';
import { Helmet } from 'react-helmet';
import './styles/App.css';


const Gallery = lazy(() => import('./components/GalleryReroute'));

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      dispatch(loadUserDataAction(user));
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <div className="App">
        {GetLocation()}
        <Helmet>
          <title>Zöldellő Apartman Siófok</title>
          <meta name="description" content="Apartmanunk a legjobb helyen, 150 méterre van a Balaton aranypartjától és az éjszakai élet központjától, a Petőfi sétánytól, mégis zöld, nyugodt, csendes övezetben." />
        </Helmet>
        <Header />
        <CookieConsentBar />
        <Suspense fallback={<div><Loader /></div>}>
          <Routes>
            <Route exact path="/" element={<LandingPage />} />
            <Route path="/admin" element={<Login />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/prices" element={<Prices />} />
            <Route path="/lazy" element={<Loader />} />
            <Route path="*" element={<NotImplementedYet />} />
          </Routes>
        </Suspense>
        <Footer />
      </div>
    </Router>
  );
}

export default App;