import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Translator } from '../index';
import { PricesExplainText } from '../index';
import { PriceUpdater } from './PriceUpdater';
import './Prices.css'; // Import the CSS file

export const Prices = () => {
    const [prices, setPrices] = useState({
        lowSeasonWeekday: 110000,
        lowSeasonWeekend: 118000,
        midSeasonWeekday: 122000,
        midSeasonWeekend: 137000,
        highSeasonWeekday: 141000,
        highSeasonWeekend: 157000,
      });
    
      const navigate = useNavigate();
      const handleSubmit = () => {
        navigate('/contactus');
      };
    
      useEffect(() => {
        const updatePrices = async () => {
          const lowSeasonWeekday = await PriceUpdater(280);
          const lowSeasonWeekend = await PriceUpdater(300);
          const midSeasonWeekday = await PriceUpdater(310);
          const midSeasonWeekend = await PriceUpdater(350);
          const highSeasonWeekday = await PriceUpdater(360);
          const highSeasonWeekend = await PriceUpdater(400);
    
          setPrices({
            lowSeasonWeekday,
            lowSeasonWeekend,
            midSeasonWeekday,
            midSeasonWeekend,
            highSeasonWeekday,
            highSeasonWeekend,
          });
        };
    
        updatePrices();
      }, []);


    return (
        <div className="custom-container">
            <div className="row">
                <div className="custom-card mb-4">
                    <div className="card h-100">
                        <div className="card-header">
                            {Translator('Elő-, Utószezon 05.01-05.31ig és 08.31-12.31ig', 'Low-season between 01.05-31.05 and 31.08-31.12', 'Nebensaison zwischen 01.05-31.05 und 31.08-31.12')}
                        </div>
                        <div className="card-body">
                            <ul className="list-unstyled">
                                <li className="d-flex justify-content-between"><b>{Translator('Teljes ház hétköznap', 'Entire house on weekdays', 'Ganzes Haus an Wochentagen')}</b> <span>{Translator(`${prices.lowSeasonWeekday} HUF/éj (Akár 16 férőhely)`, '280 EUR/Night (Up to 16 Person)', '280 EUR/Nacht (Bis zu 16 Personen)')}</span></li>
                                <li className="d-flex justify-content-between"><b>{Translator('Teljes ház hétvégén', 'Entire house on weekends', 'Ganzes Haus am Wochenende')}</b> <span>{Translator(`${prices.lowSeasonWeekend} HUF/éj (Akár 16 férőhely)`, '300 EUR/Night (Up to 16 Person)', '300 EUR/Nacht (Bis zu 16 Personen)')}</span></li>
                                <li className="d-flex justify-content-between"><b>{Translator('25% Árengedmény', '25% Discount', '25% Rabatt')}</b> <span>{Translator('Több mint 7 éjszakás tartózkodásnál', 'Over 7 nights stay', 'Bei einem Aufenthalt von mehr als 7 Nächten')}</span></li>
                                <li className="d-flex justify-content-between"><b>{Translator('50% Árengedmény', '50% Discount', '50% Rabatt')}</b> <span>{Translator('10 év alatti gyermekeknek', 'For children under 10 years of age', 'Für Kinder unter 10 Jahren')}</span></li>
                            </ul>
                            <div className="button-container">
                                <button onClick={handleSubmit} className="btn btn-success">{Translator('ELÉRHETŐSÉG', 'CONTACT US', 'KONTAKTIEREN SIE UNS')}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="custom-card mb-4">
                    <div className="card h-100">
                        <div className="card-header">
                            {Translator('Közép szezon 06.01-06.31ig', 'Mid-season between 01.06-31.06', 'Mittelsaison zwischen 01.06-31.06')}
                        </div>
                        <div className="card-body">
                            <ul className="list-unstyled">
                                <li className="d-flex justify-content-between"><b>{Translator('Teljes ház hétköznap', 'Entire house on weekdays', 'Ganzes Haus an Wochentagen')}</b> <span>{Translator(`${prices.midSeasonWeekday} HUF/éj (Akár 16 férőhely)`, '310 EUR/Night (Up to 16 Person)', '310 EUR/Nacht (Bis zu 16 Personen)')}</span></li>
                                <li className="d-flex justify-content-between"><b>{Translator('Teljes ház hétvégén', 'Entire house on weekends', 'Ganzes Haus am Wochenende')}</b> <span>{Translator(`${prices.midSeasonWeekend} HUF/éj (Akár 16 férőhely)`, '350 EUR/Night (Up to 16 Person)', '350 EUR/Nacht (Bis zu 16 Personen)')}</span></li>
                                <li className="d-flex justify-content-between"><b>{Translator('25% Árengedmény', '25% Discount', '25% Rabatt')}</b> <span>{Translator('Több mint 7 éjszakás tartózkodásnál', 'Over 7 nights stay', 'Bei einem Aufenthalt von mehr als 7 Nächten')}</span></li>
                                <li className="d-flex justify-content-between"><b>{Translator('50% Árengedmény', '50% Discount', '50% Rabatt')}</b> <span>{Translator('10 év alatti gyermekeknek', 'For children under 10 years of age', 'Für Kinder unter 10 Jahren')}</span></li>
                            </ul>
                            <div className="button-container">
                                <button onClick={handleSubmit} className="btn btn-success">{Translator('ELÉRHETŐSÉG', 'CONTACT US', 'KONTAKTIEREN SIE UNS')}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="custom-card mb-4">
                    <div className="card h-100">
                        <div className="card-header">
                            {Translator('Főszezon 07.01-09.15ig', 'High-season between 01.07-15.09', 'Hochsaison zwischen 01.07-15.09')}
                        </div>
                        <div className="card-body">
                            <ul className="list-unstyled">
                                <li className="d-flex justify-content-between"><b>{Translator('Teljes ház hétköznap', 'Entire house on weekdays', 'Ganzes Haus an Wochentagen')}</b> <span>{Translator(`${prices.highSeasonWeekday} HUF/éj (Akár 16 férőhely)`, '360 EUR/Night (Up to 16 Person)', '360 EUR/Nacht (Bis zu 16 Personen)')}</span></li>
                                <li className="d-flex justify-content-between"><b>{Translator('Teljes ház hétvégén', 'Entire house on weekends', 'Ganzes Haus am Wochenende')}</b> <span>{Translator(`${prices.highSeasonWeekend} HUF/éj (Akár 16 férőhely)`, '400 EUR/Night (Up to 16 Person)', '400 EUR/Nacht (Bis zu 16 Personen)')}</span></li>
                                <li className="d-flex justify-content-between"><b>{Translator('25% Árengedmény', '25% Discount', '25% Rabatt')}</b> <span>{Translator('Több mint 7 éjszakás tartózkodásnál', 'Over 7 nights stay', 'Bei einem Aufenthalt von mehr als 7 Nächten')}</span></li>
                                <li className="d-flex justify-content-between"><b>{Translator('50% Árengedmény', '50% Discount', '50% Rabatt')}</b> <span>{Translator('10 év alatti gyermekeknek', 'For children under 10 years of age', 'Für Kinder unter 10 Jahren')}</span></li>
                            </ul>
                            <div className="button-container">
                                <button onClick={handleSubmit} className="btn btn-success">{Translator('ELÉRHETŐSÉG', 'CONTACT US', 'KONTAKTIEREN SIE UNS')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12">
                    <PricesExplainText />
                </div>
            </div>
        </div>
    );
};