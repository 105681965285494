import React from 'react';
import { Translator } from '../index';

export const PricesExplainText = () => {
  return (
    <div className="explainTextBox">
      <div>
        {Translator(
          '*Lépj velünk kapcsolatba egyéni ajánlatokért!',
          '*Contact us for a private offer!',
          '*Kontaktieren Sie uns für ein individuelles Angebot!'
        )}
      </div>
      <div>
        {Translator(
          '*A foglaláshoz 30% előleg utalása szükséges.',
          '*For a reservation to be final, 30% of the price must be transfered in advance.',
          '*Für eine Reservierung muss eine Anzahlung von 30% des Preises im Voraus überwiesen werden.'
        )}
      </div>
      <div>
        {Translator(
          '*18 éves kor felett 550 HUF idegenforgalmi adó fizetendő.',
          '*Over 18 years of age, a non-resident tax of 550 HUF/Person must be paid.',
          '*Über 18 Jahre muss eine Fremdenverkehrsabgabe von 550 HUF/Person bezahlt werden.'
        )}
      </div>
    </div>
  );
};