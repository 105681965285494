import { useSelector } from 'react-redux';

export const Translator = (firstString, secondString, thirdString) => {
  const language = useSelector((state) => state.language.language);
  if (!firstString && !secondString && !thirdString) {
    throw Error('All language equivalents are required');
  }
  switch (language) {
    case 'eng':
      return secondString;
    case 'ger':
      return thirdString;
    case 'hun':
    default:
      return firstString;
  }
};