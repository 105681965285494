import React from 'react';
import '../../styles/LandingPage.css';
import { SectionOne, SectionTwo, SectionThree } from '../index';

export const LandingPage = () => {
  return (
    <div className="landingPageContainer">
      <div className="section">
        <SectionOne />
      </div>
      <div className="section">
        <SectionTwo />
      </div>
      <div className="section">
        <SectionThree />
      </div>
    </div>
  );
};