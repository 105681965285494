import s1 from './galleryImages/s1.JPG';
import s2 from './galleryImages/s2.JPG';
import s3 from './galleryImages/s3.JPG';
import s4 from './galleryImages/s4.JPG';
import s5 from './galleryImages/s5.JPG';
import s6 from './galleryImages/s6.JPG';
import s7 from './galleryImages/s7.JPG';
import s8 from './galleryImages/s8.JPG';
import s9 from './galleryImages/s9.JPG';
import s10 from './galleryImages/s10.JPG';
import s11 from './galleryImages/s11.JPG';
import s12 from './galleryImages/s12.JPG';
import s13 from './galleryImages/s13.JPG';
import s14 from './galleryImages/s14.JPG';
import s15 from './galleryImages/s15.JPG';
import s16 from './galleryImages/s16.JPG';
import s17 from './galleryImages/s17.JPG';
import s18 from './galleryImages/s18.JPG';
import s19 from './galleryImages/s19.JPG';
import s20 from './galleryImages/s20.JPG';
import s21 from './galleryImages/s21.JPG';
import s22 from './galleryImages/s22.JPG';
import s23 from './galleryImages/s23.JPG';
import s24 from './galleryImages/s24.JPG';
import s25 from './galleryImages/s25.JPG';
import s26 from './galleryImages/s26.JPG';
import s27 from './galleryImages/s27.JPG';
import s28 from './galleryImages/s28.JPG';
import s29 from './galleryImages/s29.JPG';
import s30 from './galleryImages/s30.JPG';
import s31 from './galleryImages/s31.JPG';
import s32 from './galleryImages/s32.JPG';
import s33 from './galleryImages/s33.JPG';
export const images = [s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11];
export const lowerImages = [
  s12,
  s13,
  s14,
  s15,
  s16,
  s17,
  s18,
  s19,
  s20,
  s21,
  s22,
  s23,
  s24,
  s25,
  s26,
  s27,
  s28,
  s29,
  s30,
  s31,
  s32,
  s33
];
